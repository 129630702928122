import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase()));
        setCity(data.city);
        setCity(decodedCity);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  const imageUrls = [
    'https://i.ibb.co/XYpb4Pq/photo-2024-04-22-20-58-25.jpg',
    'https://i.ibb.co/Qp3Rhgn/photo-2024-04-22-20-58-23.jpg',
    'https://i.ibb.co/j3JmkXt/photo-2024-04-22-20-58-21.jpg',
    'https://i.ibb.co/41XYZR5/cropped-photo-2024-04-22-20-57-38.jpg',
    'https://i.ibb.co/SnR2wxG/cropped-photo-2024-04-22-20-57-44.jpg',
    'https://i.ibb.co/XYpb4Pq/photo-2024-04-22-20-58-25.jpg',
    'https://i.ibb.co/Qp3Rhgn/photo-2024-04-22-20-58-23.jpg',
    'https://i.ibb.co/j3JmkXt/photo-2024-04-22-20-58-21.jpg',
    'https://i.ibb.co/41XYZR5/cropped-photo-2024-04-22-20-57-38.jpg',
    'https://i.ibb.co/SnR2wxG/cropped-photo-2024-04-22-20-57-44.jpg'
  ];

  return (
    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/4P32ThP/cropped-photo-2024-04-22-20-57-44-modified.png'/>
      <section>
          <div className='flexRow'>Kate😈 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            20 year old Latina in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/katexvip/c5" id="customButton">
      SUBSCRIBE NOW!
    </a>

    <p className="exclusiveContent">
    <span>
    Do you want to hook up?🔥 40% OFF For 10 PEOPLE ONLY {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />}📍<br/><br/>
              I’m looking for a content partner👀 in {city} 📍Dm me on OnlyFans to film content with me🎥❤️
              </span>
    </p>
    <Analytics/>
  </div>
  );
}

export default App;
